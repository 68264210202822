import React from 'react';
import HargaKredit from './HargaKredit';
import CatatanPembeli from './CatatanPembeli';
import ProductContent from './ProductContent';

const ProductOverview = ({data}) => {
    return (
        <div style={{marginTop: 16,width:'100%',flexWrap: 'wrap'}}>
          <div style={STYLE_MARGIN}/>
          <HargaKredit data={data}/>
          <ProductContent data={data}/>
          <CatatanPembeli/>
          <div style={STYLE_MARGIN}/>
        </div>
    );
}

const STYLE_MARGIN = {
  marginBottom: 16
}


export default React.memo(ProductOverview);