import React from 'react'
import Typography from '@material-ui/core/Typography';
import MyLink from '../Routes/MyLink';
import { Button } from '@material-ui/core';

const Logo = () => {
    return (
        <MyLink to='/'>
            <Button color='inherit'>
                <Typography variant="h6" noWrap>
                    ELCO MOTOR
                </Typography>
            </Button>
        </MyLink>
    )
}

export default React.memo(Logo)
