import React, { useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProductMotor from './Product/ProductMotor';
import ProductDetail from './Product/ProductDetail';
import LandingPage from './LandingPage/LandingPage';
import Footer from './Footer/Footer';
import SearchBar from './AppBar/Search/SearchBar'
import NavMenu from './AppBar/NavMenu';
import Logo from './AppBar/Logo';
import ContactPage from './Contact/ContactPage';
import ScrollToTop from './Routes/ScrollToTop';
import InitAllStoreData from './InitAllStoreData'

const useStyles = makeStyles((theme) => ({
  appbarOffset: theme.mixins.toolbar,
  content: {
    padding: theme.spacing(1)
  },
  root: {
    position: 'relative',
    flexGrow: 1,
    zIndex: 1
  },
}));

export default function MainNav() {
  const classes = useStyles();
  
  return (
    <Router>
      <ScrollToTop/>
      <div className={classes.root}>
        <InitAllStoreData/>
        <RenderAppBar />
        <div className={classes.appbarOffset} />
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={12} lg={1} />
            <Grid container item xs={12} lg={10} style={{position:'relative'}}>
              <AllRoutes />
            </Grid>
            <Grid item xs={12} lg={1} />
          </Grid>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

const AllRoutes = () => (
  <React.Fragment>
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/product' component={ProductMotor} />
      <Route exact path='/productdetail/:idMotor/:desc' component={ProductDetail} />
      <Route exact path='/contact' component={ContactPage} />
    </Switch>
  </React.Fragment>
)

const RenderAppBar = () => {
  const [mobileSearch, setMobileSearch] = useState(false)
  const refSearchBar = useRef()

  const searchMobileOnClick = useCallback(()=> {
    setMobileSearch(true)
    setTimeout(()=> {
      refSearchBar.current.focus()
    },300)
    
  },[refSearchBar]) 

  return (
    <AppBar position='fixed'>
      <Toolbar>
        <Logo />
        <SearchBar show={mobileSearch} setShow={setMobileSearch} refSearchBar={refSearchBar}/>
        <div style={{ flexGrow: 1 ,backgroundColor:'green'}}/>
        <NavMenu searchOnClick={searchMobileOnClick}/>
      </Toolbar>
    </AppBar>
  )
}

