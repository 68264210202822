import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

const useStyles = makeStyles(theme => ({
    plRoot: {
        marginTop: theme.spacing(1),
        width:'100%',
    },
    kategoriText: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        maxWidth: 150,
        height: 50
    },
    kategoriSec: {
        marginBottom: theme.spacing(2)
    },
    card: {
        height: "100%",
    },
    media: {
        height: 0,
        width: '100%',
        paddingBottom:'100%',
    },
}))

function ProductMotorSk () {
    const classes = useStyles()
    const count = 8
    return (
        <div className={classes.plRoot} id='product-motor-skeleton'>
            <div className={classes.kategoriSec}>
                <Grid container spacing={1}>
                    {Array(count).fill(1).map((v,index)=> (
                        <Grid item xs={6} sm={4} md={3} xl={2} key={index}>
                            <CardSk />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div >
    )
}

const CardSk = () => {
    const classes = useStyles()

    return (
        <Card className={classes.card}>
            <Skeleton variant="rect" className={classes.media}/>
            <CardContent>
               <Skeleton variant='text' /> 
               <Skeleton variant='text' width='20%'/> 
               <Skeleton variant='text' width='40%'/> 
            </CardContent>
        </Card>
    )
}

export default ProductMotorSk