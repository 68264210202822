import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import ProductGallerySk from './ProductGallerySk'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  gallery: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      margin: -theme.spacing(1)
    }
  },
}))

function ProductDetailSk() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={5}>
          <div className={classes.gallery}>
            <ProductGallerySk />
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
        </Grid>
        <Grid item xs={12}>
          {/* <ProductOverview data={data}/> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default ProductDetailSk
