import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import { fMoney } from '../../../ut'
import { deepOrange,red } from '@material-ui/core/colors'
import MyLink from '../../Routes/MyLink';
import Img from '../../Img'
import {blue} from '@material-ui/core/colors'
import {urlStringSpaceRemove} from '../../../ut'

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 1,
        height: 90,
    },
    media: {
        pointerEvents: 'none',
        width: 90,
        height: 90,
        marginRight:8,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 12,
        // whiteSpace: 'nowrap',
    },
    subtitle: {
        marginTop: -1,
    },
    otr: {
        fontWeight: 'bold',
        color: deepOrange[500],
    },
    detailRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 4,
        paddingLeft: 0,
    }
}));

const MotorCard = ({ data, handleClose }) => {
    const classes = useStyles()

    const image = data.image && data.image.primarySingle ? data.image.primarySingle : '' 
    const status = !data.inStock ? <OutOfStock/> : data.promo ? <PromoBadge/> : null
    return (
        <li style={{ position: 'relative', listStyleType: 'none' }} onClick={handleClose}>
            <MyLink to={`/productdetail/${data.idGroupKendaraan}/${urlStringSpaceRemove(data.NamaMotor)}`}>
                <div className={classes.root} >
                    <div style={{ display: 'flex' }}>
                        <Img className={classes.media}
                            src={image}
                            alt={data.NamaMotor}
                        />
                        <div className={classes.detailRoot}>
                            {status}
                            <Typography className={classes.title} variant='body2'>{data.NamaMotor}</Typography>
                            <Typography className={classes.subtitle} variant='caption'>{`(${data.Tahun})`}</Typography>
                            <Typography className={classes.otr} variant='caption'>{fMoney(data.otr)}</Typography>
                        </div>
                    </div>
                </div>
            </MyLink>
        </li>
    )
}

const OutOfStock = () => {
    const classes = useStyles()
    return (
        <div style={{display:'flex'}}>
            <Typography 
                variant='body2'
                className={classes.title}
                style={{
                    color:'white',
                    backgroundColor: red[500],
                    paddingLeft:2,
                    paddingRight:2,
                    borderRadius:3,
                }}
            >
                STOK KOSONG
            </Typography>
        </div>
    )
}


const PromoBadge = () => {
    const classes = useStyles()
    return (
        <div style={{display:'flex'}}>
            <Typography 
                variant='body2'
                className={classes.title}
                style={{
                    color:'white',
                    backgroundColor: blue[500],
                    paddingLeft:2,
                    paddingRight:2,
                    borderRadius:3,
                }}
            >
                Promo
            </Typography>
        </div>
    )
}
export default MotorCard