import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'
import SearchIcon from '@material-ui/icons/Search';
import AppsRounded from '@material-ui/icons/AppsRounded';
import { Button, Tooltip, Hidden, IconButton } from '@material-ui/core';
import MyLink from '../Routes/MyLink';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

const NavMenu = React.memo(({ searchOnClick }) => {
  return (
    <React.Fragment>
      <Hidden xsDown>
        <DesktopNavMenus />
      </Hidden>
      <Hidden smUp>
        <MobileNavMenus searchOnClick={searchOnClick} />
      </Hidden>
    </React.Fragment>
  )
})

const DesktopNavMenus = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {menuList.map((m, index) => (
        <div key={index}>
          <Hidden mdDown>
            <MyLink to={m.path} key={index}>
              <Button style={{ minWidth: 130 }} size='large' startIcon={m.icon} color='inherit'>
                {m.name}
              </Button>
            </MyLink>
          </Hidden>
          <Hidden lgUp>
            <Tooltip title={m.name} >
              <MyLink to={m.path} key={index}>
                <IconButton color='inherit'>
                  {m.icon}
                </IconButton>
              </MyLink>
            </Tooltip>
          </Hidden>
        </div>
      ))}
    </div>
  )
}

const MobileNavMenus = ({ searchOnClick }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const RenderMobileMenu = () => {

    const handleMobileMenuClick = (() => {
      setMenuAnchorEl(null)
    })

    return (
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {menuList.map((m, index) => (
          <MyLink to={m.path} key={index}>
            <MenuItem style={{ marginRight: 20 }} onClick={handleMobileMenuClick}>
              <IconButton to={m.path} color="inherit">
                {m.icon}
              </IconButton>
              <p>{m.name}</p>
            </MenuItem>
          </MyLink>
        ))}
      </Menu>
    )
  }

  return (
    <div>
      <IconButton onClick={searchOnClick} color="inherit">
        <SearchIcon />
      </IconButton>
      {/* <MobileChatMenu/> */}

      {/*if only one menu render menu icon*/}
      {menuList.length === 1 &&
        <MyLink to={menuList[0].path}>
          <IconButton to={menuList[0].path} color="inherit">
            {menuList[0].icon}
          </IconButton>
        </MyLink>
      }
      {/*if more than one menu use menu list pop up*/}
      {menuList.length > 1 &&
        <>
          <IconButton onClick={handleMenuOpen} color="inherit">
            <AppsRounded />
          </IconButton>
          <RenderMobileMenu />
        </>
      }
    </div>
  )
}

// const MobileChatMenu = () => {
//     const notificationBadge = useStoreState(state => state.chat.notificationBadge)
//     const setOpenChat = useStoreActions(action => action.chat.setOpen)
//     const handleOnClick = () => {
//         setOpenChat(true)
//     }
//     return (
//         <IconButton onClick={handleOnClick} color="inherit">
//             <Badge badgeContent={notificationBadge} color="secondary">
//                 <ChatRoundedIcon />
//             </Badge>
//         </IconButton>
//     )
// }

// const OrderFormIcon = () => {
//     const formData = useStoreState(state => state.orderForm.value)
//     const notificationBadge = formData.orderItems.reduce((total,currVal)=> total += currVal.orderAmount,0)
//     return (
//             <Badge badgeContent={notificationBadge} color="secondary">
//                 <LibraryBooksRoundedIcon />
//             </Badge>
//     )
// }

const menuList = [
  // {
  //     name: 'Form Order',
  //     icon: <OrderFormIcon />,
  //     path: '/orderform',
  // },
  {
    name: 'Contact',
    icon: <PhoneRoundedIcon />,
    path: '/contact'
  },
]

export default NavMenu