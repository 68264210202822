import { Typography } from '@material-ui/core';
import React from 'react';
import ProductMotor from '../Product/ProductMotor';
import { fTimestamp } from '../../ut'
import { useStoreState } from 'easy-peasy';

const LandingPage = () => {
    return (
        <div style={{ width: '100%' }}>
            <UpdateStatus/>
            <div style={{marginBottom:16}} />
            <ProductMotor />
            <div style={STYLE_SPACING} />
        </div>
    );
}

const UpdateStatus = () => {
    const productData = useStoreState(state => state.product.data)
    if (productData == null || productData.createdAt == null) return null
    const createdAt = fTimestamp(productData.createdAt.toDate())
    return <Typography variant='subtitle2' style={{opacity:0.8}}>{`Update Stok Terakhir: ${createdAt}`}</Typography>
}

const STYLE_SPACING = {
    marginBottom: 32,
}

export default LandingPage