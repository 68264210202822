import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  const refLastPath = useRef(history.location.pathname)
  useEffect(() => {
    const unlisten = history.listen((location,action) => {
      if (action === 'PUSH' && refLastPath.current !== location.pathname) {
        window.scrollTo(0, 0);
      }
      refLastPath.current = location.pathname
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (null);
}

export default withRouter(ScrollToTop);