import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import MyGallery from './MyGallery';
import ProductInfo from './ProductInfo'
import ProductOverview from './ProductOverview';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import ProductDetailSk from '../Skeleton/ProductDetailSk';

const useStyles = makeStyles(theme => ({
    root: {
        width:'100%'
    },
    gallery: {
        padding: theme.spacing(0),
        minHeight: 100,
        [theme.breakpoints.down('sm')]: {
            margin: -theme.spacing(1)
        }
    },
}))

function ProductDetail() {
    const classes = useStyles()
    const { idMotor } = useParams()
    const productList = useStoreState(state=> state.product.dataUnify)
    
    if (productList == null || productList.length === 0) {
        return <ProductDetailSk/>
    }
    const data = productList.find(p=> p.idGroupKendaraan+'' === idMotor)
    if (data == null) {
        return (<Typography variant='h4'>Motor Not Found</Typography>)
    }
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <div className={classes.gallery}>
                        <MyGallery data={data}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    <ProductInfo data={data}/>
                </Grid>
                <Grid item xs={12}>
                    <ProductOverview data={data}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default ProductDetail;