import { IconButton } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import React, { useEffect } from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import '../../css/image-gallery/styles.css'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const MyGallery = ({data={}}) => {
    const preferences = useStoreState(state => state.preferences.data)
    const fallbackImageUrl = preferences.fallbackImage ? preferences.fallbackImage : ''

    useEffect(()=> { // just to prevent image-gallery collapsing on initial load
      const timeout = setTimeout(() => {
          const el = document.querySelector('.prevent-images-collapse')
          if (el) {
            el.classList.remove('prevent-images-collapse')
          }
      }, 1000);
      return ()=> clearTimeout(timeout)
    },[])

    const imagesArr = data.image && data.image.gallery? data.image.gallery : [] 
    const galleryImages = imagesArr.map(img => (
        {
          original: img,
          thumbnail: img,
        }
    ))
  
    return (
        <ImageGallery 
            items={galleryImages}
            onErrorImageURL={fallbackImageUrl}
            showPlayButton={false}
            showFullscreenButton={false}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            additionalClass='no-save prevent-images-collapse'
        />

    )
}

const renderLeftNav = (onClick, disabled) => {
  return (
    <IconButton onClick={onClick} disabled={disabled} disableRipple style={STYLE_LEFT_NAV}>
      <ArrowBackIosRoundedIcon/>
    </IconButton>
  )
}

const renderRightNav = (onClick, disabled) => {
  return (
    <IconButton onClick={onClick} disabled={disabled} disableRipple style={STYLE_RIGHT_NAV}>
      <ArrowForwardIosRoundedIcon/>
    </IconButton>
  )
}

const STYLE_LEFT_NAV = {
    position:'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    zIndex:2,
}
const STYLE_RIGHT_NAV = {
  position:'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  zIndex:2,
}
export default React.memo(MyGallery)