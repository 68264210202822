import React from 'react'
import { useStoreActions, useStoreState } from "easy-peasy"
import { useEffect } from "react"

const InitAllStoreData = React.memo(() => {
  const productData = useStoreState(state => state.product.data)
  const productDefinition = useStoreState(state => state.definition.data)
  const bindProductDef = useStoreState(state => state.definition.bind)
  const contentData = useStoreState(state => state.content.data)

  const getDatabank = useStoreActions(action => action.databank.getData)
  const getKredit = useStoreActions(action => action.kredit.getData)
  const getProduct = useStoreActions(action => action.product.getData)
  const getProductImage = useStoreActions(action => action.product.getImage)
  const getDefinition = useStoreActions(action => action.definition.getData)
  const getProductBindDef = useStoreActions(action => action.definition.getBind)
  const getPreferences = useStoreActions(action => action.preferences.getData)
  const getContent = useStoreActions(action => action.content.getData)

  const setProDataUnify = useStoreActions(action => action.product.setDataUnify)

  useEffect(()=> {
    getDatabank()
    getKredit()
    getProduct()
    getProductImage()
    getDefinition()
    getPreferences()
    getProductBindDef()
    getContent()
  },[getDatabank,getKredit,getProduct,getProductImage,getDefinition,getPreferences,getProductBindDef,getContent,])

  useEffect(()=> {
    if (productData.products == null) return
    
    // insert image
    const proArr = productData.products.map(pro => {
      const bindId = bindProductDef[pro.idGroupKendaraan]
      if (bindId == null) return {...pro}
      if (productDefinition[bindId] == null) return {...pro}

      const proDef = productDefinition[bindId]
      const primary = proDef.primary?proDef.primary: []
      const gallery = proDef.gallery?proDef.gallery: []
      const primarySingle = primary.length === 0 ? '' : primary[Math.floor(Math.random()*(primary.length-1))]
      
      return {...pro, image: {
        primary,
        primarySingle,
        gallery,
      }}
    })

    // insert content
    const proArr2 = proArr.map(pro => {
      const bindId = bindProductDef[pro.idGroupKendaraan]
      if (bindId == null) return {...pro}
      if (productDefinition[bindId] == null) return {...pro}
      const proDef = productDefinition[bindId]

      const idArr = proDef.contentIds ? proDef.contentIds : []
      const contentArr = idArr.map(id => contentData[id]).filter(c => c != null)


      return {...pro, content: contentArr}
    })

    // console.log(proArr2)
    setProDataUnify(proArr2)
  },[productData,productDefinition,bindProductDef,contentData,setProDataUnify])

  return <></>
})

export default InitAllStoreData