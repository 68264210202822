import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy';
import MotorCard from './MotorCard';
import { Typography } from '@material-ui/core';

const MotorResult = ({ searchText, handleClose }) => {
    const productList = useStoreState(state => state.product.dataUnify)

    const [result, setResult] = useState([])
    const [noResult, setNoResult] = useState(false)
    useEffect(() => {
        if (productList == null) return
        const timeoutF = setTimeout(() => {
            if (searchText.length === 0) {
                setResult([])
                setNoResult(false)
                return
            }
            let tempArr = productList.filter(pro => pro.NamaMotor.toLowerCase().includes(searchText.toLowerCase()))
            setResult(tempArr)
            setNoResult(tempArr.length === 0)
        }, 300);
        return () => clearTimeout(timeoutF)
    }, [searchText, productList])

    if (productList == null || searchText == null) {
        return <></>
    }

    if (noResult){
        return <NoResult/>
    }
    if (searchText.length === 0){
        return <NoText/>
    }

    return (
        <React.Fragment>
            {result.map((pro, index) => (
                <MotorCard data={pro} key={index} index={index} handleClose={handleClose}/>
            ))}
        </React.Fragment>
    )
}

const NoResult = () => {
    return (
        <Typography 
            align='center'
            style={{padding: 16}}
            variant='body2'
        >
            Tidak Ada Hasil
        </Typography>
    )
}

const NoText = () => {
    return (
        <Typography 
            align='center'
            style={{padding: 16}}
            variant='body2'
        >
            Cari Motor
        </Typography>
    )
}

export default MotorResult