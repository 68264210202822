import React from 'react'
import { Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

const Badge = ({ title=''}) => {
    if (title === '') {
        return <></>
    }
    return (
            <Typography 
                variant='subtitle2' 
                style={{
                    display: 'inline',
                    backgroundColor: blue[500],
                    color:'white',
                    padding: '2px 5px 2px 5px',
                    marginRight:5,
                    borderRadius: 3,
                }}
            >
                {title}
            </Typography>
    )
}

export default Badge