import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/general/styles.css'

const MyLink = React.forwardRef(({children,to,onClick, ...props},ref) => {
    const handleClick = (location) => {
        if (onClick) {
            return onClick()
        } else if (to) {
            return to
        } else {
            return location.pathname
        }
    }
    
    return (
        <Link className={'no-select'} ref={ref} to={handleClick} {...props} style={{color: 'inherit', textDecoration: 'inherit'}}>{children}</Link>
    )
})

export default MyLink
