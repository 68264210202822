
const COLOR_BLUE = '#2196f3'
const COLOR_GREY = '#757575'

const COLOR_GREEN = '#4caf50'
const COLOR_ORANGE = '#ff9800'
const COLOR_RED = '#f44336'

const PHONE_TYPE_INCOMING = 1;
const PHONE_TYPE_OUTGOING = 2;
const PHONE_TYPE_MISS = 3;

const SALES_REPORT = "Sales Report";
const COST_REPORT = "Cost Report";
const INVENTORY_REPORT = "Inventory Report";

const chartColors = [
  '#E8C1A0',
  '#F47560',
  '#F1E15B',
  '#E8A838',
  '#61CDBB',
  '#97E3D5',
]

const getYearRange = (year = 5) => {
  return [
    new Date(new Date(new Date().setFullYear(new Date().getFullYear() - year)).setDate(1)),
    new Date(new Date(new Date().setMonth(new Date().getMonth()+1)).setDate(0))
  ]
}



const STYLE_MARGIN = {
  marginBottom: 5
}

const STYLE_CARD_DIV = {
  backgroundColor: "#fff",
  borderRadius: 5,
  padding: 8
};

const dayNames = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
]

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember"
];

function fMoney(amount, decimalCount = 0, decimal = ",", thousands = ".") {
  try {
    if (amount == null){
      return ''
    }else if (isNaN(amount)){
      return 'NaN'
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
    return 'error'
  }
};

function percentage (value,total) {
  return Math.floor(value / total * 100 * 10) / 10
}

function fIndoDate(date, noDay = false, numberMonth = false) {
  try {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    if (noDay) {
      return monthNames[monthIndex] + " " + year;
    }
  
    if (numberMonth){
      return (day < 10 ? "0"+day : day ) + "-" + (monthIndex+1 < 10 ? "0"+(monthIndex+1) : monthIndex+1) + "-" + year;
    }
  
    return day + " " + monthNames[monthIndex] + " " + year;
  }catch (err) {
    console.log(err)
    return 'error'
  }

}

function fTimestamp(date) {
  if (date === undefined) {
    return ''
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()

  if (day < 10) { day = '0' + day }
  if (month < 10) { month = '0' + month }
  if (hour < 10) { hour = '0' + hour }
  if (minute < 10) { minute = '0' + minute }
  if (second < 10) { second = '0' + second }
  return `${day}-${month}-${year} ${hour}:${minute}:${second}`
}

function sqlDateTimeToDate(string) {
  string = string.replace('T',' ')
  let t = string.split(/[- :.]/);
  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
}

function fRevDate(date) {
  let day = date.getDate();
  let monthIndex = date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + (monthIndex < 10 ? "0" + monthIndex : monthIndex) + "-" + (day < 10 ? "0" + day : day)
}

function isRevDateString(dateString) {
  const regex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')
  if (typeof dateString === 'string' && regex.test(dateString)) {
    const splited = dateString.split('-')
    const [month, day] = [splited[1], splited[2]]
    if (month > 12 || day > 31) {
      return false
    }
    return true
  }
  return false
}

function getDateMonthRange(date,plusEnd = false) {
  const start = new Date(new Date(date).setDate(1))
  let end 
  if (plusEnd){
    end = new Date(date.getFullYear(), date.getMonth() + 1, 1)
  }else{
    end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  }

  return [start, end]
}

function addDayDate (date,number){
  let d = new Date(date)
  return new Date(new Date(d).setDate(d.getDate() + number))
}

function secondToHourMinSec(sec) {
  if (typeof sec !== 'number') {
    return 'NaN'
  }

  let sec_num = parseInt(sec, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

function fDateToTime (date, withoutSecond = false){
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; } 

  if (withoutSecond){
    return hours + ':' + minutes;
  }
  
  return hours + ':' + minutes + ':' + seconds;
}

function secondToMinSec(sec) {
  if (typeof sec !== 'number') {
    return 'NaN'
  }

  let sec_num = parseInt(sec, 10); // don't forget the second param
  let minutes = Math.floor(sec_num / 60);
  let seconds = sec_num - (minutes * 60);

  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return minutes + ':' + seconds;
}

function sqlTimeToSecond (time) {
  const t = time.split(':')
  return (parseInt(t[0])*3600)+(parseInt(t[1]*60))+parseInt(t[2])
}

function removeTime(date){
  return new Date(new Date(date).setHours(0,0,0,0))
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function isTheSameDay(date1,date2) {
  return new Date(date1).setHours(0,0,0,0) === new Date(date2).setHours(0,0,0,0)
}

const myScrollTo = (id, offset = 0) => {
  const element = document.getElementById(id);
  if(element){
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validIndoPhoneNum(number) {
  if (number == null) return false
  number = String(number).replace(/[-\s]/g,'') 
  const re = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/
  return re.test(String(number).toLowerCase());
}

function urlStringSpaceRemove (str) {
  if (str && typeof str === 'string') {
    return str.trim().replace(/\s/g,'-')
  }
  return 'undefined'
}

module.exports = {
  fMoney, fIndoDate, fRevDate, isRevDateString, getDateMonthRange, monthNames, secondToHourMinSec, COLOR_BLUE, COLOR_GREY, STYLE_MARGIN, STYLE_CARD_DIV,
  SALES_REPORT, COST_REPORT, INVENTORY_REPORT, getYearRange, PHONE_TYPE_INCOMING, PHONE_TYPE_MISS, PHONE_TYPE_OUTGOING,
  COLOR_GREEN, COLOR_ORANGE, COLOR_RED, secondToMinSec, fTimestamp, sqlDateTimeToDate,sqlTimeToSecond,removeTime,fDateToTime,
  addDayDate,chartColors,percentage,randomDate,isTheSameDay,dayNames,myScrollTo,validateEmail,validIndoPhoneNum,urlStringSpaceRemove,
}