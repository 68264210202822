import React, { useEffect } from 'react';
import { Typography, Divider, makeStyles, Grid, Tooltip, Button, Select, MenuItem, Hidden, useMediaQuery, TextField } from '@material-ui/core';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { green, grey } from '@material-ui/core/colors'
import Badge from './Badge'
import { useState } from 'react';
import { fMoney, myScrollTo } from '../../ut'
import { useStoreState } from 'easy-peasy';
// import AddRoundedIcon from '@material-ui/icons/AddRounded';
// import MySnackbar from '../MySnackbar'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2)
    }
  },
  divider: {
    margin: '16px 0px 16px 0px',
  },
  dividerTitle: {
    marginBottom: 8,
  },
  marginList: {
    marginBottom: theme.spacing(2)
  },
  selectComp: {
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 10000
    }
  }
}))

const ProductInfo = React.memo(({ data }) => {
  const classes = useStyles()
  const [selWilayah, setSelWilayah] = useState('')
  const [selColor, setSelColor] = useState('')

  return (
    <div className={classes.root}>
      {data.promo && <Badge title='Promo Tersedia' />}
      <Title data={data} />
      <Divider className={classes.dividerTitle} />
      <Harga data={data} />
      <Divider className={classes.divider} />
      <HargaKredit data={data} />
      <ProductColorAvailablity data={data} selColor={selColor} setSelColor={setSelColor}/>
      <Divider className={classes.divider} />
      <ContactSelector data={data} selWilayah={selWilayah} setSelWilayah={setSelWilayah} selColor={selColor}/>
    </div>
  );
})

const ContactSelector = ({data,selWilayah,setSelWilayah,selColor}) => {
  const classes = useStyles()
  const databank = useStoreState(state => state.databank.data)
  const wilayahArr = databank.wilayah? databank.wilayah : []

  useEffect(()=> {
    const savedWilayah = localStorage.getItem('wilayah')
    if (savedWilayah != null) {
      setSelWilayah(savedWilayah)
    }
  },[setSelWilayah])

  const handleOnChange = (e) => {
    localStorage.setItem('wilayah',e.target.value)
    setSelWilayah(e.target.value)
  }

  let infoText
  if (data.inStock) infoText = 'Motor bisa expedisi ke dealer Elco Motor terdekat anda.'
  else infoText = 'Silakan hubungi untuk Inden atau tanyakan ketersedian stok'

  const styleWilSel = {
    marginBottom:8,
    marginTop:8,
  }
  
  const styleNotWilSel = {
    marginBottom:8,
  }

  return (
    <Grid container style={{padding:8}}>
      <Grid item md={2}>
        <Typography color='textSecondary'>Hubungi</Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <TextField style={selWilayah===''?styleNotWilSel:styleWilSel} select label='Wilayah' variant='outlined' value={selWilayah} onChange={handleOnChange} fullWidth className={classes.selectComp}>
          {wilayahArr.map((ac, index) => (
            <MenuItem value={ac} key={index}>{ac}</MenuItem>
          ))}
        </TextField>
        <ContactList data={data} selWilayah={selWilayah} selColor={selColor}/>
        <Typography style={{display:'block'}} variant='caption' color='textSecondary' gutterBottom>{infoText}</Typography>

      </Grid>
    </Grid>
  )
}


const ContactList = ({data,selWilayah,selColor=''}) => {
  const databank = useStoreState(state => state.databank.data)
  const cabang = databank.cabang? databank.cabang: {}
  const cabangArr = Object.keys(cabang).map(key => cabang[key])
  const predefinedText = databank.predefinedText? databank.predefinedText : ''
  if (selWilayah == null || selWilayah === '')  return <></>

  const wilayahContact = cabangArr.find(ca => ca.wilayah === selWilayah)
  const textWhatsapp = `${data.NamaMotor} - ${selColor}, ${predefinedText}`
  return (
    <div style={{marginBottom:16}}>
      <ContactButton data={wilayahContact} text={textWhatsapp}/>
    </div>
  )
}

const ContactButton = React.memo(({data, text}) => {
  const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));
  if (data == null) return <Typography color='error'>Tidak ada cabang</Typography>

  const number = data.contact && data.contact.length > 0? data.contact[0].no : ''
  const name = data.nama ? data.nama : 'No Name'

  const formattedNum = number.replace(/[-\s]/g, '').replace(/^08/, '+628')
  const ContactIcons = () => (
    <span style={{ margin: -5 }}>
      <FontAwesomeIcon color='#2CB23F' size='lg' style={{ marginRight: 2 }} icon={faWhatsappSquare} />
    </span>
  )
  
  return (
    <div>
      <Typography variant='h6'>{name}</Typography>
      <Typography style={{display:'block'}} variant='caption' color='textSecondary'>{'klik untuk chat whatsapp'}</Typography>
      <a href={`https://wa.me/${formattedNum}?text=${text}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Button
          color='primary'
          variant='outlined'
          fullWidth={matches}
          startIcon={<ContactIcons />}
        >
          {number}
        </Button>
      </a>
    </div>
  )
})

const Title = React.memo(({ data }) => {
  return (
    <div style={{ padding: 8 }}>
      {/* {promo != null &&
                <Badge title='PROMO' message={data.promo} />
            } */}
      <Typography variant='h4' >{data.NamaMotor}</Typography>
      <Typography variant='subtitle2' color='textSecondary'>{`${data.idMotor} - ${data.kodeMotor} (${data.Tahun})`}</Typography>
    </div>
  )
})

const Harga = React.memo(({ data }) => {

  const HargaComp = () => {
    if (data.discount != null && data.discount > 0) {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <Typography variant='caption' style={{
              backgroundColor: '#F44336',
              borderRadius: 5,
              padding: '0px 5px 0px 5px',
              marginRight: 5,
              color: 'white',
            }}>Discount</Typography>
            <Typography variant='caption' style={{ textDecorationLine: 'line-through', color: 'grey' }}>{fMoney(data.otr)}</Typography>
          </div>
          <Typography variant='h5'>{fMoney(data.otr - data.discount)}</Typography>
        </>
      )
    } else {
      return (
        <Typography variant='h5'>{fMoney(data.otr)}</Typography>
      )
    }
  }

  return (
    <Grid container style={{ padding: 8 }}>
      <Hidden smDown>
        <Grid item sm={2}>
          <Typography color='textSecondary'>Harga</Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={10}>
        <HargaComp />
        <Typography variant='caption' color='textSecondary'>Harga Sepeda motor Honda yang tertera disini tidak mengikat dan dapat berubah sewaktu waktu tanpa pemberitahuan terlebih dahulu.</Typography>
      </Grid>
    </Grid>
  )
})

const HargaKredit = React.memo(() => {
  const classes = useStyles()
  const [isKredit, setIsKredit] = useState(false)

  const handleOnClick = () => {
    myScrollTo('harga-kredit-product', -75)
  }

  useEffect(() => {
    const el = document.getElementById('harga-kredit-product')
    if (el) setIsKredit(true)
  }, [])

  if (!isKredit) return null

  return (
    <div>
      <Grid container style={{ padding: 8 }}>
        <Hidden smDown>
          <Grid item sm={2}>
            <Typography color='textSecondary'>Kredit</Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={10}>
          <Button onClick={handleOnClick} variant='outlined' style={{ marginTop: -10, marginBottom: 8 }} size='large' startIcon={<ArrowDownwardRoundedIcon />}>Harga Kredit Tersedia Di Bawah</Button>
          <Typography style={{ display: 'block' }} variant='caption' color='textSecondary'>Harga Kredit di Update Setiap Bulan Silakan Hubungi Elco Motor Untuk Harga Akhir.</Typography>
        </Grid>

      </Grid>
      <Divider className={classes.divider} />
    </div>
  )
})

const ProductColorAvailablity = React.memo(({ data,selColor, setSelColor }) => {
  const classes = useStyles()

  useEffect(() => setSelColor(''),[setSelColor])

  const handleChange = (e) => {
    setSelColor(e.target.value)
  }

  const availableColor = data.colors ? Object.keys(data.colors).sort() : []
  useEffect(()=> {
    if (availableColor.length === 1 && selColor !== availableColor[0]) {
      setSelColor(availableColor[0])
    }
  },[selColor,setSelColor,availableColor])

  
  if (!data.inStock) {
    return (
      <Typography align='center' color='error' variant='h6' gutterBottom>STOK KOSONG</Typography>
    )
  }

  return (
    <div style={{ padding: 8 }}>
      <Grid container>
        <Grid item md={2}>
          <Typography color='textSecondary'>Warna</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Select variant='outlined' value={selColor} onChange={handleChange} fullWidth className={classes.selectComp}>
            {availableColor.map((ac, index) => (
              <MenuItem value={ac} key={index}>{ac}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <div className={classes.divider} />
      <ProductAvailableLocation selectedColor={selColor} data={data} />
      {/* <OrderButton data={data} selectedColor={selectedColor} /> */}
    </div>
  )
})

const ProductAvailableLocation = ({ selectedColor = '', data }) => {
  if (data == null) {
    return <></>
  }

  let availableLocation
  let allLoactionSet = new Set()
  Object.keys(data.colors).forEach(color => {
    data.colors[color].forEach(l => { allLoactionSet.add(l) })
  })
  availableLocation = [...allLoactionSet].sort().map(l => { return { location: l, inStock: true } })

  if (selectedColor !== '') {
    availableLocation.forEach(v => v.inStock = false)
    const locationColor = data.colors[selectedColor] ? data.colors[selectedColor].sort() : []
    locationColor.forEach(lc => {
      const locationMatch = availableLocation.find(l => l.location === lc)
      if (locationMatch) {
        locationMatch.inStock = true
      }
    })
    availableLocation.sort((a, b) => {
      if (a.inStock && b.inStock) {
        if (a.location < b.location) return -1
        if (a.location > b.location) return 1
        return 0
      }
      else if (a.inStock && !b.inStock) return -1
      else if (!a.inStock && b.inStock) return 1
      else return 0
    })
  }

  const LocationAvailableItem = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data.inStock &&
        <Tooltip title={`Tersedia di ${data.location}`}>
          <CheckCircleRoundedIcon style={{ color: green[500] }} />
        </Tooltip>
      }
      {!data.inStock &&
        <Tooltip title={`Warna Tidak Tersedia di ${data.location}`}>
          <RemoveCircleRoundedIcon style={{ color: grey[400] }} />
        </Tooltip>
      }
      <div style={{ marginRight: 5 }} />
      <Typography variant='body1'>{data.location}</Typography>
    </div>
  )

  const LocationAvailable = ({ data }) => {
    const warpLimit = 5
    return (
      <Grid container>
        {data.length > warpLimit &&
          <Grid item md={6} xs={12}>
            {data.map((al, index, arr) => (
              <React.Fragment key={index}>
                {index <= ((Math.ceil(arr.length / index + 1)) - 1) &&
                  <LocationAvailableItem key={index} data={al} />
                }
              </React.Fragment>
            ))}
          </Grid>
        }
        {data.length > warpLimit &&
          <Grid item md={6} xs={12}>
            {data.map((al, index, arr) => (
              <React.Fragment key={index}>
                {index > ((Math.ceil(arr.length / index + 1)) - 1) &&
                  <LocationAvailableItem key={index} data={al} />
                }
              </React.Fragment>
            ))}
          </Grid>
        }
        {data.length <= warpLimit &&
          <Grid item md={6} sm={12}>
            {data.map((al, index) => (
              <React.Fragment key={index}>
                <LocationAvailableItem key={index} data={al} />
              </React.Fragment>
            ))}
          </Grid>
        }
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item md={2}>
        <Typography color='textSecondary'>Tersedia</Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <LocationAvailable data={availableLocation} />
      </Grid>
    </Grid>
  )
}

// const OrderButton = ({ data, selectedColor }) => {
//     const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));
//     const addOrderItems = useStoreActions(action => action.orderForm.addOrderItems)
//     const history = useHistory()
//     const disabled = selectedColor == null || selectedColor === ''
//     const [openSnackbar, setOpenSnackbar] = useState(false)

//     const handleBuyNow = () => {
//         let ob = { ...data }
//         ob.selectedColor = selectedColor
//         addOrderItems([ob])
//         history.push('/orderform')
//     }

//     const handleAddCart = () => {
//         let ob = { ...data }
//         ob.selectedColor = selectedColor
//         addOrderItems([ob])
//         setOpenSnackbar(true)
//     }

//     return (
//         <Grid container>
//             <Grid item md={2}></Grid>
//             <Grid container item xs={12} md={10} spacing={1}>
//                 <Grid item xs={12}>
//                     {selectedColor === '' && <Typography gutterBottom variant='body2' color='error'>*Silakan Pilih Warna Terlebih dahulu</Typography>}
//                     <Button
//                         disabled={disabled}
//                         color='primary'
//                         variant='contained'
//                         fullWidth={matches}
//                         onClick={handleBuyNow}
//                     >
//                         Order Sekarang
//                         </Button>
//                     <Button
//                         disabled={disabled}
//                         color='primary'
//                         variant='outlined'
//                         startIcon={<AddRoundedIcon />}
//                         fullWidth={matches}
//                         onClick={handleAddCart}
//                         style={matches ? { marginLeft: 0, marginTop: 8 } : { marginTop: 0, marginLeft: 8 }}
//                     >
//                         Keranjang
//                         </Button>
//                     <MySnackbar message={`(${data.NamaMotor}) di tambah ke order form`} open={openSnackbar} setOpen={setOpenSnackbar} severity='success' />
//                 </Grid>
//                 <Grid item xs={12}>
//                     <ContactButton />
//                 </Grid>
//             </Grid>
//         </Grid>
//     )
// }



export default ProductInfo;