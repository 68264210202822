import React from 'react';
import { Card, makeStyles, CardContent, Typography, CardActionArea, useTheme } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors'
import Badge from './Badge';
import { fMoney, urlStringSpaceRemove } from '../../ut'
import MyLink from '../Routes/MyLink';
import Img from '../Img';

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
  },
  media: {
    position: 'absolute',
    height: '100%',
    pointerEvents: 'none'
  },
  mediaWrapper: {
    position: 'relative',
    paddingBottom: '100%',
  }
}))

const ProductCard = ({ data }) => {
  const classes = useStyles()

  if (data == null) {
    return <></>
  }

  const handleOnClick = () => {
    if (data != null) {
      return `/productdetail/${data.idGroupKendaraan}/${urlStringSpaceRemove(data.NamaMotor)}`
    }
  }

  const HargaComp = () => {
    if (data.discount != null && data.discount > 0) {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <Typography variant='caption' style={{
              backgroundColor: '#F44336',
              borderRadius: 5,
              padding: '0px 5px 0px 5px',
              marginRight: 5,
              color: 'white',
            }}>Disc</Typography>
            <Typography variant='caption' style={{ textDecorationLine: 'line-through', color: 'grey' }}>{fMoney(data.otr)}</Typography>
          </div>
          <Typography variant='subtitle2' gutterBottom style={{ color: deepOrange[500] }}>
            {fMoney(data.otr - data.discount)}
          </Typography>
        </>
      )
    } else {
      return (
        <Typography variant='subtitle2' gutterBottom style={{ color: deepOrange[500] }}>
          {fMoney(data.otr)}
        </Typography>
      )
    }
  }

  const imgPrimary = data.image && data.image.primarySingle? data.image.primarySingle : undefined 
  return (
    <MyLink onClick={handleOnClick}>
      <Card className={classes.card} >
        <CardActionArea>
          <div className={classes.mediaWrapper}>
            <Img className={classes.media}
              alt={data.NamaMotor}
              src={imgPrimary}
            />

          </div>
          <CardContent style={{ position: 'relative' }}>
            {!data.inStock && <OutOfStock />}
            <Typography variant='subtitle2' >
              {data.NamaMotor}
            </Typography>
            <Typography variant='body2' color='textSecondary' gutterBottom>
              {data.Tahun}
            </Typography>
            <HargaComp />
            {data.promo && <Badge title='Promo Tersedia' />}
          </CardContent>
        </CardActionArea>
      </Card>
    </MyLink>

  );
}


const OutOfStock = () => {
  const theme = useTheme()
  return (
    <Typography variant='subtitle2' color='error'
      style={{
        position: 'absolute',
        top: -25,
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor: theme.palette.error.main,
        color: 'white',
        fontSize: 16,
      }}
    >
      STOK KOSONG
    </Typography>
  )
}

export default ProductCard;