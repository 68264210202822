import React from 'react'
import Ckeditor5Content from '../Ckeditor5Content'

function ProductContent({ data = {} }) {
  const dataArr = data.content ? data.content : []
  return (
    <div style={{ width: '100%' }}>
      {dataArr.map((d, index) => (
        <div key={index} style={{marginBottom: 16}}>
          <Ckeditor5Content dangerouslySetInnerHTML={d.data ? d.data : ''} />
        </div>
      ))}
    </div>
  )
}

export default React.memo(ProductContent)