import React from 'react';
import MainNav from './components/MainNav'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { store } from './store'
import { StoreProvider } from 'easy-peasy';
import smoothscroll from 'smoothscroll-polyfill'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F44336',
    },
    secondary: {
      main: '#448AFF',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

theme.shadows[1] = '0px 1px 6px 0px rgba(0,0,0,0.1)'
// console.log(theme)
function App() {
  smoothscroll.polyfill()
  return (
    <div>
      <ThemeProvider theme={theme}>
        <StoreProvider store={store}>
          <MainNav />
        </StoreProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;