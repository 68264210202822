import { makeStyles } from '@material-ui/core'
import React from 'react'

const breakpoints = ['xs','sm','md','lg','xl']

function GridItem({children,...props}) {
    const classes = makeStyles(theme => {
        const { spacing } = props
        let itemInRow = 1
        let ob = {}
        breakpoints.forEach(br => {
            if (props[br] != null) {
                ob[theme.breakpoints.up(br)] = { gridTemplateColumns: `repeat(${props[br]},1fr)` }
            }
        })
        return {
            root: {
                display: 'grid',
                gap: `${spacing}px`,
                gridTemplateColumns: `repeat(${itemInRow},1fr)`,
                ...ob
            }
        }
    })()

    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}

export default GridItem
