import React from 'react'
import '../css/ckeditor/content-styles.css'
import { Typography } from '@material-ui/core';

const Ckeditor5Content = ({dangerouslySetInnerHTML}) => {
    if (dangerouslySetInnerHTML == null) {
      return <></>
    }
    const createMarkup = () => {
      const openTagExp = /<figure class="table"><table>/g
      const openTagReplacement = '<div class="table-responsive"><figure class="table"><table>'
      const closingTagExp = /<\/table><\/figure>/g
      const closingTagReplacement = '</table></figure></div>'

      const firstRep = dangerouslySetInnerHTML.replace(openTagExp,openTagReplacement)
      const secondRep = firstRep.replace(closingTagExp,closingTagReplacement)
      return {__html: secondRep};
    }
  
    return (
      <Typography component={'span'}>
        <div className='ck-content' dangerouslySetInnerHTML={createMarkup()} style={{overflow:'hidden',textOverflow:'ellipsis'}}/>
      </Typography>
    )
}

export default React.memo(Ckeditor5Content)