import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare,faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { Typography, IconButton } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';

const list = [
    {
        name: 'facebook',
        icon: <FontAwesomeIcon color='#3B5998' icon={faFacebookSquare}/>
    },
    {
        name: 'instagram',
        icon: <FontAwesomeIcon color='#D93174' icon={faInstagramSquare}/>
    },
]

const SocialMedia = () => {
    const databank = useStoreState(state=> state.databank.data)
    const socialMedia = databank.socialMedia

    if (socialMedia == null) return null

    return (
        <div>
            <Typography variant='h6'>Social Media</Typography>
            {list.map((v,index)=> (
                <Item data={v} link={socialMedia[v.name] && socialMedia[v.name].url ? socialMedia[v.name].url : ''} key={index}/>
            ))}
        </div>
    );
}

const Item = ({data,link}) => {
    if (link == null || link === '' ) return null
    return (
        <a target="_blank" rel="noopener noreferrer" href={link}>
            <IconButton>
                {data.icon}
            </IconButton>
        </a>
    )
}

export default SocialMedia;