import React from 'react';
import { makeStyles, Grid, Divider } from '@material-ui/core';
import SocialMedia from './SocialMedia';
import Schedule from './Schedule';
import CabangDealer from './CabangDealer';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            padding: 0,
        }
    },
    divider: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
    },
    spacing: {
        marginBottom: theme.spacing(3),
    },
    gridItem: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        }
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                    <Schedule />
                    <div className={classes.spacing} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                    <div id='cabang-dealer'/>
                    <CabangDealer />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                    <SocialMedia />
                    <div className={classes.spacing} />
                </Grid>
            </Grid>
        </div>
    );
}

const FooterContainner = () => {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Divider className={classes.divider} />
            <Grid container>
                <Grid item xs={12} lg={1} />
                <Grid container item xs={12} lg={10}>
                    <Footer />
                </Grid>
                <Grid item xs={12} lg={1} />
            </Grid>
        </React.Fragment>
    )
}

export default React.memo(FooterContainner);