import { createStore, action, thunk } from "easy-peasy";
import firebaseApp from './firebase/firebase'
import { getFirestore, getDoc, doc } from "firebase/firestore";

const db = getFirestore(firebaseApp)

export const store = createStore({
    databank: {
        data: {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "databank")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),
    },
    kredit: {
        data: {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "kredit_v2")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),
    },
    product: {
        data: {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "products")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),

        image: {},
        getImage: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "productImage")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setImage(docSnap.data())
            }
        }),
        setImage: action((state, payload) => { state.image = payload }),

        dataUnify: [],
        setDataUnify: action((state, payload) => { state.dataUnify = payload }),
    },
    definition: {
        data: {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "productDefinition")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),
        bind: {},
        getBind: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "bindProductDefinition")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setBind(docSnap.data())
            }
        }),
        setBind: action((state, payload) => { state.bind = payload }),
    },
    preferences: {
        data : {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "preferences")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),
    },
    content: {
        data: {},
        getData: thunk(async (action, payload) => {
            const docRef = doc(db, "allInOne", "content")
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                action.setData(docSnap.data())
            }
        }),
        setData: action((state, payload) => { state.data = payload }),
    },
})

