import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import MyLink from '../Routes/MyLink'

const CabangDealer = () => {
  const databank = useStoreState(state => state.databank.data)
  const cabangDealer = databank.cabang
  if (cabangDealer == null) return null
  const displayList = Object.keys(cabangDealer).map(key=> cabangDealer[key]).map(v => v.nama).filter(v => v != null).sort()
  return (
    <div >
      <Typography variant='h6' gutterBottom>Cabang Dealer</Typography>
      {displayList.map((cabang, index, arr) => (
        <React.Fragment key={index}>
          <MyLink to='/contact'>
            <Button size='small'>{cabang}</Button>
          </MyLink>
          {arr.length !== index + 1 &&
            <span>-</span>
          }
        </React.Fragment>
      ))}
    </div>
  );
}

export default CabangDealer;