import { useStoreState } from 'easy-peasy';
import React from 'react';
import Ckeditor5Content from '../Ckeditor5Content';

const CatatanPembeli = () => {
    const databank = useStoreState(state=> state.databank.data)
    const dataString = databank.catatanPembeli
    return (
        <Ckeditor5Content dangerouslySetInnerHTML={dataString}/>
    );
}

export default React.memo(CatatanPembeli);