import { useStoreState } from 'easy-peasy';
import React from 'react';
import Ckeditor5Content from '../Ckeditor5Content';
import Img from '../Img';

const HargaKredit = () => {
  const kreditData = useStoreState(state => state.kredit.data)

  const kreditText = kreditData.text? kreditData.text : ''
  const kreditImgs = kreditData.images? kreditData.images : []
  return (
    <div id='harga-kredit-product'>
      <Ckeditor5Content dangerouslySetInnerHTML={kreditText} />
      <ImageList dataArr={kreditImgs}/>
    </div>
  );
}

const ImageList = ({dataArr}) => {
  return (
    <div style={{width:'100%'}}>
      {dataArr.map((d,index) => <Img key={index} style={{width:'100%'}} src={d}/>)}
    </div>
  )
}

export default React.memo(HargaKredit);