import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

var firebaseConfig = {
    apiKey: "AIzaSyAvi2XWwPhZiSuLJPbBFdBFhS-jUhSK6_w",
    authDomain: "elco-webapp.firebaseapp.com",
    databaseURL: "https://elco-webapp.firebaseio.com",
    projectId: "elco-webapp",
    storageBucket: "elco-webapp.appspot.com",
    messagingSenderId: "368867879087",
    appId: "1:368867879087:web:878f28e62f0a6ec084cc5a",
    measurementId: "G-ZS87HEV6HB"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const isLocalhostOrLocalNet = Boolean(window.location.hostname.match(/(^127\.0\.0\.1)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.)|localhost/))

if (!isLocalhostOrLocalNet) {
    getAnalytics(firebaseApp)
    console.log('analytic initialize.')
}

export default firebaseApp



