import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const useStyles = makeStyles(theme=> ({
    root: {
        height:'100%',
    },
    mediaWrapper: {
        height: "100%",

    },
    media: {
        height: 0,
        paddingBottom:'100%',
    },
    thumbnailRoot: {
        display: 'flex',
        width: '100%',
        overflow:'hidden'
    },
    thumbnail: {
        height: 80,
        width: 80,
        margin: '8px 8px 0px 0px',
    },
}))

function ProductGallerySk() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.mediaWrapper}>
                <Skeleton variant='rect' className={classes.media}/>
            </div>
            <div className={classes.thumbnailRoot}>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
                <div><Skeleton variant='rect' className={classes.thumbnail}/></div>
            </div>
        </div>
    )
}

export default ProductGallerySk
