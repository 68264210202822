import React from 'react';
import { Typography } from '@material-ui/core';

const Schedule = () => {
    return (
        <div>
            <Typography variant='h6' gutterBottom>Jam Operasional</Typography>
            <Typography>Senin - Sabtu : 08:00 - 16:00</Typography>
            <Typography>Minggu : 08:00 - 12:00</Typography>
        </div>
    );
}

export default Schedule;