import { ClickAwayListener, fade, Hidden, IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import InputBase from '@material-ui/core/InputBase';
import SearchResult from './SearchResult';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px 0px 8px',
    backgroundColor: theme.palette.primary.main,
    opasity: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      opasity: 0,
      position: 'relative',
      marginLeft: theme.spacing(4),
      padding: 0,
    },
  },
  search: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    padding: '2px 10px',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up('sm')]: {
      padding: '4px 2px',
      maxWidth: 350,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
    },
  },

  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 0),
    width: '100%',
  },
  hiddenInput: {
    position: 'absolute',
    top: -300,
    left: 0,
  }
}));

const SearchBar = ({ show, setShow, refSearchBar }) => {
  const matches = useMediaQuery(theme => theme.breakpoints.down('xs'))
  return (
    <div style={{ flexGrow: 1 }}>
      {((show && matches) || !matches) &&
        <SearchBarRoot setShow={setShow} refSearchBar={refSearchBar} />
      }
    </div>
  )
}

const SearchBarRoot = React.memo(({ setShow, refSearchBar }) => {
  const classes = useStyles()
  const history = useHistory()
  const [searchVal, setSearchVal] = useState('')
  const [showResult, setShowResult] = useState(false)
  const matches = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const handleSearchClear = () => {
    setSearchVal('')
    refSearchBar.current.focus()
  }

  const handleClose = () => {
    if (setShow) setShow(false)
  }

  const handleClickAway = () => {
    if (refSearchBar.current !== document.activeElement && showResult) {
      handleCloseResult()
    }
  }

  const handleSearchFocus = () => {
    setShowResult(true)
  }

  const handleCloseResult = () => {
    setShowResult(false)
    handleClose()
    if (refSearchBar && refSearchBar.current) refSearchBar.current.blur()
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const queryOb = queryString.parse(history.location.search)
      queryOb.search = searchVal
      history.push({
        pathname: '/',
        search: queryString.stringify(queryOb)
      })
      handleCloseResult()
    }
    else if (e.key === 'ArrowDown') {
      const el = document.getElementById('search-bar-hidden-input')
      if (el) el.focus()
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <div className={classes.search}>
          <HiddenInput handleCloseResult={handleCloseResult}/>
          {showResult &&
            <SearchResult searchText={searchVal} refSearchBar={refSearchBar} handleClose={handleCloseResult} />
          }
          <div className={classes.searchIcon}>
            <Hidden xsDown>
              <SearchIcon />
            </Hidden>
            <Hidden smUp>
              <IconButton edge={matches ? 'start' : false} onClick={handleClose} color='inherit' style={{ padding: 0 }}>
                <ArrowBackIcon />
              </IconButton>
            </Hidden>
          </div>
          <InputBase
            id='search-bar'
            autoComplete='off'
            inputRef={refSearchBar}
            value={searchVal}
            onChange={event => setSearchVal(event.target.value)}
            placeholder="Cari Motor..."
            onFocus={handleSearchFocus}
            onKeyDown={handleKeyPress}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <div className={classes.searchIcon} style={searchVal.length > 0 ? { display: 'block' } : { display: 'none' }}>
            <IconButton edge={matches ? 'end' : false} onClick={handleSearchClear} color='inherit' style={{ padding: 0 }}>
              <CancelRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
})

const HiddenInput = ({handleCloseResult}) => {
  const classes = useStyles()

  const handleHiddenInputKey = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      handleArrowNav(e.key)
    }
    else if (e.key === 'Enter') {
      if (handleSelectedEnter()) {
        handleCloseResult()
        const el = document.getElementById('search-bar-hidden-input')
        if (el) el.blur()
      }
    }
  }

  return (
    <input
      id='search-bar-hidden-input'
      type='text'
      tabIndex='-1'
      className={classes.hiddenInput}
      onKeyDown={handleHiddenInputKey}
      onBlur={()=> handleSelectedRemove()}
      onFocus={()=> handleArrowNav('ArrowDown')}
    />
  )
}

const handleArrowNav = (keyPressed) => {
  const selected = document.querySelector('#search-result-root li.selected')
  if (selected) {
    if (keyPressed === 'ArrowDown' && selected.nextElementSibling) {
      selected.classList.remove('selected')
      selected.nextElementSibling.classList.add('selected')
      selected.nextElementSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
    else if (keyPressed === 'ArrowUp') {
      selected.classList.remove('selected')
      if (selected.previousElementSibling) { // go up normally
        selected.previousElementSibling.classList.add('selected')
        selected.previousElementSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      } else { // hit top limit goes back to search bar
        const el = document.getElementById('search-bar')
        if (el) {
          el.focus()
        }
      }
    }
  } else {
    if (keyPressed === 'ArrowDown') {
      const elements = document.querySelectorAll('#search-result-root li')
      if (elements.length > 0) {
        elements[0].classList.add('selected')
      }
    }
  }
}

const handleSelectedEnter = () => {
  const selected = document.querySelector('#search-result-root li.selected')
  if (selected) {
    selected.firstChild.click()
    return true
  }
  return false
}

const handleSelectedRemove = () => {
  const selected = document.querySelector('#search-result-root li.selected')
  if (selected) {
    selected.classList.remove('selected')
  }
}

export default React.memo(SearchBar)