import { Card, makeStyles } from '@material-ui/core';
import React from 'react'
import MotorResult from './MotorResult';
import '../../../css/search-bar/styles.css'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        color: theme.palette.text.primary,
        backgroundColor: 'white',
        top: 58 - 4,
        left: 0,
        right: 0,
        minWidth: 300,
        // border: '1px solid green',
        borderRadius: 2,
        margin: 0,
        overflow: 'auto',
        height: 'auto',
        maxHeight:'calc(100vh - 30vh)',
        overscrollBehavior:'none',
        [theme.breakpoints.down('xs')]: {
            top: 48 - 4,
            right: 0,
            margin: '0px -8px 0px -8px',
            width: 'auto',
            maxHeight:'initial',
            height:'calc(100vh - 48px)',
        }
    },
}));

function SearchResult({ searchText, handleClose }) {
    const classes = useStyles()
    return (
        <Card className={classes.root}>
            <ul id='search-result-root' className='search-bar' style={{listStyleType: 'none',margin: 0, padding: 0}}>
                <MotorResult searchText={searchText} handleClose={handleClose}/>
            </ul>
        </Card>
    )
}



export default SearchResult
