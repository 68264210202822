import { useStoreState } from 'easy-peasy'
import React, { useState } from 'react'

function Img({alt,src,style,...props}) {
    const [errLoadImage, setErrLoadImage] = useState(false)
    const preferences = useStoreState(state=> state.preferences.data)
    const fallbackImage = preferences.fallbackImage
    return (
        <img {...props}
            src={src?src:fallbackImage}
            alt={alt}
            style={{borderRadius: 2,...style}}
            loading='lazy'
            onError={e => {
                if (!errLoadImage) {
                    setErrLoadImage(true)
                    e.target.src = fallbackImage
                }
            }}
        />
    )
}

export default Img
