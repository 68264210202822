import React from 'react';
import { CardContent, Typography} from '@material-ui/core';

// const PlaceholderProductCard = ({data}) => {
//     return (
//         <div style={{height: 200}} >
//         </div>

//     );
// }

const PlaceholderProductCard = ({data}) => {
    return (
        <div style={card} >
            <div style={mediaWrapper}/>
            <CardContent style={cardContent}>
                <Typography variant='subtitle2' >
                    {data.NamaMotor}
                </Typography>
                <Typography variant='body2' color='textSecondary' gutterBottom>
                    {data.Tahun}
                </Typography>
                <Typography variant='subtitle2' gutterBottom>
                    {data.otr}
                </Typography>
            </CardContent>
        </div>

    );
}

const card = {
    height: "100%",
    // backgroundColor:'green'
}
const cardContent = {
    position: 'relative'
}
const mediaWrapper = {
    position: 'relative',
    paddingBottom: '100%',
}

export default PlaceholderProductCard;