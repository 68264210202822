import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { Typography, makeStyles, Paper, Tabs, Tab, IconButton, Tooltip, Select, MenuItem } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import '../../css/lazyload/styles.css'
import { myScrollTo } from '../../ut'
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import LazyLoad from 'react-lazyload';
import PlaceholderProductCard from './PlaceholderProductCard';
import ProductMotorSk from '../Skeleton/ProductMotorSk';
import GridItem from '../GridItem/GridItem';

const useStyles = makeStyles(theme => ({
  plRoot: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  filterContainer: {
    position: 'sticky',
    top: 70,
    flexGrow: 1,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  priceSlider: {
    flexGrow: 1,
  },
  sorting: {
    width: '100%',
  },
  appbarOffset: theme.mixins.toolbar,

  kategoriText: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  kategoriSec: {
    marginBottom: theme.spacing(2)
  },
  tabRoot: {
    position: 'sticky',
    top: 70,
    zIndex: 2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      top: 65,
    }
  },
  tabs: {
    borderRadius: 50,
    padding: '0px 20px 0px 20px',
  },
  sortingAndSearchInfo: {
    display: 'flex',
    flexDirection: 'rows',
    alignItems: 'center',
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      maxWidth: 'initial',
    }
  },
  sortingRoot: {
    display: 'flex',
    flexDirection: 'rows',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    width: 'initial',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      maxWidth: 'initial',
      width: '100%',
    }
  },
  sortingSelect: {
    maxWidth: 300,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial',
    }
  },

}))

const ProductMotor = React.memo(() => {
  const classes = useStyles()
  const productList = useStoreState(state => state.product.dataUnify)
  const preferences = useStoreState(state => state.preferences.data)
  const kategoriArr = preferences.kategoriList

  const location = useLocation()


  if (productList == null || productList.length === 0 || kategoriArr == null) {
    window.scrollTo(0, 0)
    return <ProductMotorSk />
  }

  const queries = location.search
  let displayList = productList
  let searchInfoText = ''
  let showTabKategori = true
  if (queries.length > 0) {
    const qOb = queryString.parse(queries)
    if (qOb.search && productList) {
      displayList = productList.filter(pro => pro.NamaMotor.toLowerCase().includes(qOb.search.toLowerCase()))
      searchInfoText = qOb.search
      setTimeout(() => myScrollTo('product-list', -100), 300)
    }
    if (qOb.sorting != null && Number.isInteger(parseInt(qOb.sorting)) && qOb.sorting < sortingSelectValues.length) {
      sortDisplayList(displayList, qOb.sorting)
      showTabKategori = qOb.sorting === '1' || qOb.sorting === '3'
    }
  } else {
    sortDisplayList(displayList, 3) // default sorting, no query string
  }

  const List = () => (
    <GridItem xs={2} sm={3} md={4} lg={5} xl={6} spacing={8}>
      {displayList.map((pro, index) => (
        <LazyLoad offset={100} once placeholder={<PlaceholderProductCard data={pro} />} key={index}>
          <ProductCard data={pro} />
        </LazyLoad>
      ))}
    </GridItem>
  )

  const ListWithKategori = () => (
    <React.Fragment>
      {kategoriArr.map((kat, index) => {
        let filteredPro = displayList.filter(fp => fp.type === kat)
        if (filteredPro.length === 0) {
          return <React.Fragment key={index}></React.Fragment>
        }
        return (
          <div key={index} className={classes.kategoriSec}>
            <Typography variant='h5' id={`kategori-${kat}`} className={classes.kategoriText + ' kategori-product'}>{kat}</Typography>
            <GridItem xs={2} sm={3} md={4} lg={5} xl={6} spacing={8}>
              {filteredPro.map((pro, index) => (
                <LazyLoad offset={100} once placeholder={<PlaceholderProductCard data={pro} />} key={index}>
                  <ProductCard data={pro} />
                </LazyLoad>
              ))}
            </GridItem>
          </div>
        )
      })}
    </React.Fragment>
  )
  return (
    <div className={classes.plRoot} id='product-list'>
      {showTabKategori && <TabsKategori displayList={displayList} kategoriArr={kategoriArr}/>}
      <div className={classes.sortingAndSearchInfo}>
        <SearchInfo text={searchInfoText} displayList={displayList} />
        <SortingSelect />
      </div>
      {showTabKategori && <ListWithKategori />}
      {!showTabKategori && <List />}

    </div >
  )
})



const SearchInfo = ({ text, displayList }) => {
  const history = useHistory()
  if (text == null || text.length === 0) {
    return <></>
  }

  const handleClearSearch = () => {
    const query = queryString.parse(history.location.search)
    if (query) delete query.search
    history.push({ search: queryString.stringify(query) })
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <Typography style={{ padding: 8, fontWeight: 'bold' }}>{`Menampilkan pencarian "${text}"`}</Typography>
        <Tooltip title='Hapus Search'><IconButton onClick={handleClearSearch}><CloseRoundedIcon style={{ fontSize: 14 }} /></IconButton></Tooltip>
      </div>
      {displayList.length === 0 &&
        <Typography style={{ padding: 8, fontWeight: 'bold' }}>Tidak Ada Hasil</Typography>
      }
    </React.Fragment>
  )
}

const TabsKategori = ({ displayList, kategoriArr }) => {

  const [value, setValue] = useState(0);
  const [katFiltered, setKatFiltered] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (kategoriArr) {
      let set = new Set()
      displayList.forEach(el => set.add(el.type))
      const kategoriFiltered = kategoriArr.filter(kat => set.has(kat))
      setKatFiltered(kategoriFiltered)
    }
  }, [kategoriArr, displayList])

  useEffect(() => {
    const listener = () => {
      if (katFiltered === undefined) return

      const labels = document.querySelectorAll('.kategori-product')
      let labelIndex = null
      labels.forEach(l => {
        if (document.documentElement.scrollTop >= l.offsetTop - 200) {
          labelIndex = katFiltered.indexOf(l.innerHTML)
        }
      })
      if (labelIndex != null && labelIndex !== -1) {
        setValue(labelIndex)
      } else {
        setValue(false)
      }
    }
    document.addEventListener('scroll', listener)
    return () => document.removeEventListener('scroll', listener)
  }, [katFiltered])

  if (kategoriArr == null || katFiltered == null) {
    return <></>
  }

  const handleOnClick = (kat) => {
    myScrollTo(`kategori-${kat}`, -150)
  }
  return (
    <div className={classes.tabRoot}>
      <Paper className={classes.tabs} square>
        <Tabs
          value={value}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"

        >
          {katFiltered.map((kat, index) => (
            <Tab key={index} label={kat} onClick={() => handleOnClick(kat)} />
          ))}
        </Tabs>
      </Paper>
    </div>
  )
}

const sortingSelectValues = [
  'Termurah',
  'Termurah - Kategori',
  'Nama Motor',
  'Nama Motor - Kategori',
]

const sortDisplayList = (list, selectedSort) => {
  switch (sortingSelectValues[selectedSort]) {
    case 'Termurah':
    case 'Termurah - Kategori': {
      list.sort((a, b) => {
        if (a.inStock && !b.inStock) return -1
        else if (!a.inStock && b.inStock) return 1
        else return a.otr - b.otr
      })
      break
    }
    case 'Nama Motor':
    case 'Nama Motor - Kategori': {
      list.sort((a, b) => {
        if (a.inStock && !b.inStock) return -1
        else if (!a.inStock && b.inStock) return 1
        else if (a.NamaMotor < b.NamaMotor) return -1
        else if (a.NamaMotor > b.NamaMotor) return 1
        else return 0
      })
      break
    }
    default: {
      console.log('no sorting found, no-op')
    }
  }
}

const SortingSelect = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const defaultSorting = 3
  const [value, setValue] = useState(defaultSorting) // default 'Nama Motor - Kategori'
  const handleOnChange = (e) => {
    const selectedValue = e.target.value
    const query = queryString.parse(history.location.search)
    query.sorting = selectedValue
    history.push({ search: queryString.stringify(query) })
    setValue(selectedValue)
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (query.sorting) {
      setValue(query.sorting)
    } else {
      setValue(defaultSorting) // default value
    }
  }, [location])
  return (
    <div className={classes.sortingRoot}>
      <Typography style={{ paddingRight: 8 }}>Urutkan:</Typography>
      <Select
        value={value}
        onChange={handleOnChange}
        fullWidth
        variant='outlined'
        className={classes.sortingSelect}
      >
        {sortingSelectValues.map((v, index) => (
          <MenuItem key={index} value={index}>{v}</MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default ProductMotor;