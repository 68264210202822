import React from 'react'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { blue } from '@material-ui/core/colors' 

function ContactPage() {
    const databank = useStoreState(state => state.databank.data)
    const cabangDealer = databank.cabang
    if (cabangDealer == null) return <></>

    const cabangArr = Object.keys(cabangDealer).map(key => cabangDealer[key])

    cabangArr.sort((a,b)=> {
        if (a.priority != null && b.priority == null) return -1
        else if (a.priority == null && b.priority != null) return 1
        else if (a.priority == null && b.priority == null) return 0
        else return a.priority - b.priority
    })
    return (
        <div>
            <Typography variant='h4' style={{padding:'16px 0px 0px 0px'}}>Contact</Typography>
            <Info/>
            {cabangArr.map((cb, index) => (
                <CabangItem data={cb} key={index}/>
            ))}
        </div>
    )
}

const Info = () => {
    const databank = useStoreState(state => state.databank.data)
    const contactNote = databank.contactNote
    if (contactNote == null) return null

    return (
        <Typography 
            gutterBottom 
            variant='subtitle1'
            style={{padding:'0px 0px 16px 0px'}}
        >
            {contactNote}
        </Typography>
    )
}

const CabangItem = ({ data }) => {
    return (
        <div style={{marginBottom:24}}>
            <Typography variant='h6' gutterBottom>{data.nama}</Typography>
            <NoTelponItem data={data.contact}/>
            <Typography>{data.alamat}</Typography>
        </div>
    )
}

const NoTelponItem = ({ data }) => {
    if (data == null) return <></>
    return (
        <React.Fragment>
            {data.map((d, index) => (
                <div style={{display:'flex',alignItems:'center',marginBottom:4}} key={index}>
                    <Typography style={{marginRight:4}}>{`${d.name}:`}</Typography>
                    {d.phone && <TelponButton tel={d.no}/>}
                    {d.whatsapp && <WhatsappButton tel={d.no}/>}
                    <a href={`tel:${d.noTelpon}`} style={{textDecoration:'none'}}>
                        <Typography style={{marginLeft:8}}>{d.no}</Typography>
                    </a>
                </div>
            ))}
        </React.Fragment>
    )
}

const WhatsappButton = ({tel}) => {
    if (tel == null) return null

    const unformatted = tel.replace(/[-\s]/g,'').replace(/^08/,'+628')
    return (
        <Tooltip title='Whatsapp Tersedia'>
            <a href={`https://wa.me/${unformatted}`} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>
                <IconButton style={{padding:0,paddingLeft:4}}>
                    <FontAwesomeIcon color={'#2CB23F'} size='lg' style={{marginRight:2}} icon={faWhatsappSquare}/>
                </IconButton>
            </a>
        </Tooltip>
    )
}

const TelponButton = ({tel}) => {
    if (tel == null) return null

    return (
        <Tooltip title='Telpon Tersedia'>
            <a href={`tel:${tel}`} style={{textDecoration:'none'}}>
                <IconButton style={{padding:0,paddingLeft:4}}>
                    <FontAwesomeIcon color={blue[500]} size='lg' style={{marginRight:2}} icon={faPhoneSquareAlt}/>
                </IconButton>
            </a>
        </Tooltip>
    )
}

export default ContactPage
